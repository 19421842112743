const DocMenuConfig = [
    {
        pages: [
            {
                heading: "dashboard",
                route: "/dashboard",
                svgIcon: "/media/icons/duotune/art/art002.svg",
                fontIcon: "bi-app-indicator",
            }
        ],
    },
    {
        heading: "contentManagement.title",
        pages: [
            {
                heading: "contentManagement.video.title",
                route: "/video",
                svgIcon: "/media/icons/duotune/general/gen006.svg",
                fontIcon: "bi-image",
            },
            {
                heading: "contentManagement.video.directory",
                route: "/directory",
                svgIcon: "/media/icons/duotune/files/fil012.svg",
                fontIcon: "bi-folder",
            },
            {
                heading: "contentManagement.video.group",
                route: "/video/group",
                svgIcon: "/media/icons/duotune/abstract/abs027.svg",
                fontIcon: "bi-stack",
            },
            {
                heading: "contentManagement.video.upload",
                route: "/video/upload",
                svgIcon: "/media/icons/duotune/files/fil010.svg",
                fontIcon: "bi-sd-card",
            },
            {
                heading: "contentManagement.trash",
                route: "/trash",
                svgIcon: "/media/icons/duotune/general/gen027.svg",
                fontIcon: "bi-trash",
            },
        ]
    },
    {
        heading: "sourceManagement.title",
        route: "/source",
        showUserGroup: ['CONTENT_OWNER'],
        pages: [
            {
                heading: "sourceManagement.analytic",
                route: "/source/analytic",
                svgIcon: "/media/icons/duotune/graphs/gra001.svg",
                fontIcon: "bi-chart-fill",
            },
            {
                heading: "sourceManagement.storage",
                route: "/source/storage",
                svgIcon: "/media/icons/duotune/files/fil010.svg",
                fontIcon: "bi-sd-card",
            },
            {
                heading: "sourceManagement.trafficUsage",
                route: "/source/traffic-usage",
                svgIcon: "/media/icons/duotune/graphs/gra003.svg",
                fontIcon: "bi-graph-up",
            },
        ]
    },
    {
        heading: "subscriptionManagement.title",
        route: "/subscription",
        showUserGroup: ['CONTENT_OWNER'],
        pages: [
            {
                heading: "subscriptionManagement.information",
                route: "/subscription/information",
                svgIcon: "/media/icons/duotune/general/gen045.svg",
                fontIcon: "bi-info-circle-fill",
            },
            {
                heading: "subscriptionManagement.apiManagement",
                route: "/subscription/api",
                svgIcon: "/media/icons/duotune/coding/cod001.svg",
                fontIcon: "bi-gear-fill",
            }
        ]
    },
    {
        heading: "developerManagement.title",
        route: "/developer",
        showUserGroup: ['DEVELOPER'],
        pages: [
            {
                sectionTitle: "developerManagement.customerManagement.title",
                route: "/developer/customer-management",
                svgIcon: "/media/icons/duotune/communication/com006.svg",
                fontIcon: "bi-people",
                sub: [
                    {
                        heading: "developerManagement.customerManagement.company",
                        route: "/developer/customer-management/company",
                    },
                    {
                        heading: "developerManagement.customerManagement.customer",
                        route: "/developer/customer-management/customer",
                    },
                ]
            },
            {
                heading: "developerManagement.storage",
                route: "/developer/storage",
                svgIcon: "/media/icons/duotune/files/fil010.svg",
                fontIcon: "bi-sd-card",
            },
            {
                sectionTitle: "developerManagement.videoManagement.title",
                route: "/developer/video-management",
                svgIcon: "/media/icons/duotune/general/gen006.svg",
                fontIcon: "bi-image",
                sub: [
                    {
                        heading: "developerManagement.videoManagement.downloader",
                        route: "/developer/video-management/downloader",
                    },
                    {
                        heading: "developerManagement.videoManagement.converter",
                        route: "/developer/video-management/converter",
                    },
                    {
                        heading: "developerManagement.videoManagement.migrate",
                        route: "/developer/video-management/migrate",
                    },
                ]
            },
        ]
    },
];

export default DocMenuConfig;
